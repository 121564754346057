import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="hero-section">
        <Container className="hero-container">
          <div className="hero-img"></div>
          <Row>
            <Col md={6}>
              <div className="hero-wrapper">
                <div className="hero-wrapper__inner">
                  <h1>
                    Hi,
                    <br />
                    I'm Sachin Amarasinghe.
                  </h1>
                  <p>
                    I do many things from Software Development to UI/UX Design
                    and Photography.
                  </p>
                  <div className="links">
                    <a
                      href="https://www.instagram.com/sachin_amarasinghe"
                      target="_blank"
                      className="mr-4"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://www.facebook.com/sachinamarasinghephotography"
                      target="_blank"
                      className="mr-4"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    <a
                      href="https://www.linkedin.com/in/sachinamarasinghe/"
                      target="_blank"
                      className="mr-4"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
